@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@400;600&display=swap");
html,
body {
  padding: 0;
  margin: 0;
  font-family: "Kanit", sans-serif !important;
  /* font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif; */
}

h1 {
  font-size: 1.5rem;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.container {
  padding: 0 50px 50px;
}

.itemdel {
  transition: 0.2s ease-in;
  right: -100px;
}

.listitemdel:hover > .itemdel {
  right: 0px;
}

.listmeitem {
  padding: 2px 10px;
  margin: 15px 0;
  transition: 0.1s ease-in-out;
}

.listmeitem-active {
  background-color: white;
  border-radius: 25px;
  .listmeitemdel {
    display: block;
  }
}

.listmeitem:hover {
  background-color: white;
  border-radius: 25px;
}

.listmeitemdel {
  display: none;
}

.listmeitem:hover {
  .listmeitemdel {
    display: block;
  }
}

@media only screen and (max-width: 800px) {
  .container {
    padding: 0 10px 10px;
  }
}

@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }
  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}

.background {
  position: relative;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  background: #f6d52c;
  overflow: hidden;
  opacity: 0;
}

.background li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.2);
  animation: animate 19s linear infinite;
}

.background li:nth-child(0) {
  left: 23%;
  width: 144px;
  height: 144px;
  bottom: -144px;
  animation-delay: 1s;
}

.background li:nth-child(1) {
  left: 50%;
  width: 227px;
  height: 227px;
  bottom: -227px;
  animation-delay: 2s;
}

.background li:nth-child(2) {
  left: 51%;
  width: 130px;
  height: 130px;
  bottom: -130px;
  animation-delay: 3s;
}

.background li:nth-child(3) {
  left: 3%;
  width: 140px;
  height: 140px;
  bottom: -140px;
  animation-delay: 1s;
}

.background li:nth-child(4) {
  left: 46%;
  width: 197px;
  height: 197px;
  bottom: -197px;
  animation-delay: 8s;
}

.background li:nth-child(5) {
  left: 79%;
  width: 194px;
  height: 194px;
  bottom: -194px;
  animation-delay: 3s;
}

.background li:nth-child(6) {
  left: 51%;
  width: 111px;
  height: 111px;
  bottom: -111px;
  animation-delay: 28s;
}

.background li:nth-child(7) {
  left: 34%;
  width: 126px;
  height: 126px;
  bottom: -126px;
  animation-delay: 5s;
}

.background li:nth-child(8) {
  left: 47%;
  width: 188px;
  height: 188px;
  bottom: -188px;
  animation-delay: 34s;
}

.background li:nth-child(9) {
  left: 73%;
  width: 115px;
  height: 115px;
  bottom: -115px;
  animation-delay: 35s;
}

//-----------------------------------------------------------------------------------
.card-container p {
  margin: 0;
}

.card-container > .ant-tabs-card .ant-tabs-content {
  margin-top: -16px;
}

.card-container > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
  padding: 16px;
  background: #fff;
}

.card-container > .ant-tabs-card > .ant-tabs-nav::before {
  display: none;
}

.card-container > .ant-tabs-card .ant-tabs-tab,
[data-theme="compact"] .card-container > .ant-tabs-card .ant-tabs-tab {
  background: transparent;
  border-color: transparent;
}

.card-container > .ant-tabs-card .ant-tabs-tab-active,
[data-theme="compact"] .card-container > .ant-tabs-card .ant-tabs-tab-active {
  background: #fff;
  border-color: #fff;
}

#components-tabs-demo-card-top .code-box-demo {
  padding: 24px;
  overflow: hidden;
  background: #f5f5f5;
}

[data-theme="compact"] .card-container > .ant-tabs-card .ant-tabs-content {
  margin-top: -8px;
}

[data-theme="dark"] .card-container > .ant-tabs-card .ant-tabs-tab {
  background: transparent;
  border-color: transparent;
}

[data-theme="dark"] #components-tabs-demo-card-top .code-box-demo {
  background: #000;
}

[data-theme="dark"]
  .card-container
  > .ant-tabs-card
  .ant-tabs-content
  > .ant-tabs-tabpane {
  background: #141414;
}

[data-theme="dark"] .card-container > .ant-tabs-card .ant-tabs-tab-active {
  background: #141414;
  border-color: #141414;
}

.ant-table-tbody > tr > td.tableright {
  text-align: right !important;
}

.ant-table-tbody > tr > td.tableleft {
  text-align: left !important;
}

.bodyloading {
  background-color: transparent !important;
}

.rederFooterHomepage {
  .ant-modal-footer {
    text-align: center !important;
  }
}

/* Table */
.table {
  width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table .table {
  background-color: #fff;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff;
}

.table-hover .table-primary:hover {
  background-color: #9fcdff;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #9fcdff;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db;
}

.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb;
}

.table-hover .table-success:hover {
  background-color: #b1dfbb;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb;
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #212529;
  border-color: #32383e;
}

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #212529;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #32383e;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.table-responsive > .table-bordered {
  border: 0;
}

.text-center {
  text-align: center !important;
}

#data-table-target-ad {
  .table-responsive {
    .table {
      width: 100%;
      th {
        text-align: center;
        color: #fff;
        background-color: #27509b;
        &::selection {
          color: #fff;
        }
        &:nth-child(1) {
          width: 80px;
          min-width: 80px;
        }
        &:nth-child(2) {
          width: 300px;
          min-width: 300px;
        }
        &:nth-child(3) {
          width: 120px;
          min-width: 120px;
        }
        &:nth-child(4) {
          width: 120px;
          min-width: 120px;
        }
        &:nth-child(5) {
          width: 120px;
          min-width: 120px;
        }
        &:nth-child(6) {
          width: 120px;
          min-width: 120px;
        }
        &:nth-child(7) {
          width: 120px;
          min-width: 120px;
        }
        &:nth-child(8) {
          width: 120px;
          min-width: 120px;
        }
        &:nth-child(9) {
          width: 120px;
          min-width: 120px;
        }
        &:nth-child(10) {
          width: 120px;
          min-width: 120px;
        }
        &:nth-child(11) {
          width: 120px;
          min-width: 120px;
        }
        &:nth-child(12) {
          width: 120px;
          min-width: 120px;
        }
        &:nth-child(13) {
          width: 120px;
          min-width: 120px;
        }
        &:nth-child(14) {
          width: 120px;
          min-width: 120px;
        }
        &:nth-child(15) {
          width: 120px;
          min-width: 120px;
        }
        &:nth-child(16) {
          width: 150px;
          min-width: 150px;
        }
      }
      td {
        // vertical-align: middle;
        padding-top: 20px;
        .ant-row {
          .ant-col-19 {
            max-width: 100%;
          }
        }
        .ant-input {
          border: 1px solid #04afe300;
          &:hover {
            border: 1px solid #27509b !important;
          }
        }
        .ant-input-affix-wrapper {
          border: 1px solid #04afe300;
          &:hover {
            border: 1px solid #27509b !important;
          }
        }
        .ant-select-selector {
          border: 1px solid #04afe300 !important;
          &:hover {
            border: 1px solid #27509b !important;
          }
        }
        .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
          .ant-select-selector {
          box-shadow: 0 0 0 2px #27509b;
        }
        .ant-input:focus,
        .ant-input-focused {
          box-shadow: 0 0 0 2px #27509b;
        }
        .ant-picker {
          border: 1px solid #04afe300;
          &:hover {
            border-color: #27509b;
          }
        }
        .ant-picker:focus,
        .ant-picker-focused {
          box-shadow: 0 0 0 2px #27509b;
        }
        &:nth-child(1) {
          text-align: center;
        }
        &:nth-child(2) {
          text-align: center;
        }
        &:nth-child(15) {
          text-align: center;
        }
      }
    }
  }
}

.card-summarize-container {
  width: 100%;
  padding-right: 5vw;
  padding-left: 5vw;
  margin-right: auto;
  margin-left: auto;
  .card-summarize {
    margin: 30px 0 30px 0px;
    text-align: -webkit-center;
    .ant-card {
      border-radius: 36px;
      background: #bbc7df;
      padding: 20px;
      max-width: 280px;
      height: 450px;
      .ant-card-head {
        border-bottom: 0px solid #f0f0f0;
        .ant-card-head-wrapper {
          margin: 15px;
          background: #fff;
          color: #27509b;
          font-size: 24px;
          border-radius: 30px;
          .ant-card-head-title {
            text-align: center;
          }
        }
      }

      .ant-card-body {
        padding: 40px 5px 24px 5px;
        .card-container {
          display: flex;
          flex-wrap: wrap;

          .box {
            flex: 1 1;
            border: 1px solid #f0f0f0;
            border-radius: 10px;
            color: #f0f0f0;
            background: #27509b;
            margin: 5px;
            padding: 8px 4px 0px 4px;
            font-size: 18px;
            &:nth-child(1) {
              text-align: center;
              max-width: 200px;
            }
            &:nth-child(2) {
              padding: 5px 15px 5px 5px;
              text-align: right;
            }
          }
        }
        .card-container-acheivement {
          font-size: 18px;
          padding-top: 25px;
          .box {
            &:nth-child(1) {
              border: 1px solid #f0f0f0;
              color: #f0f0f0;
              border-radius: 10px 10px 0px 0px;
              background: #27509b;
            }
            &:nth-child(2) {
              border: 1px solid #f0f0f0;
              border-radius: 0px 0px 10px 10px;
              font-size: 45px;
              background: #f0f0f0;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1600px) {
  .card-summarize-container {
    padding-right: 15px;
    padding-left: 15px;
  }
}
